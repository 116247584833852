$(function () {
    // region Проверки ввода значения и замена инпута
    const validatePhone = function (value) {
        return /^\d+$/.test(value.replace(/[-\\/()+\s]/g, ''));
    };
    const validateEmail = function (value) {
        return value.indexOf('@') >= 0 || value === 'admin';
    };
    const $inputMailOrPhone = $('.login_by_login_or_phone #input_mail_or_phone');
    $inputMailOrPhone.on('keyup blur', function (e) {
        const val = $(this).val();
        const isPhone = validatePhone(val);
        const isEmail = validateEmail(val);
        const $form = $(this).closest('form.form_login');
        if ($form.hasClass('login_by_phone') && !isPhone) {
            $form.removeClass('login_by_phone');
            $form.find('#button_login').text(CONFIG.LANG.login);
            $form.attr('action', CONFIG.URL.login);
            $(this).attr('name', 'username');
            $form.find('.input_password').attr('type', 'password');
        }
        if (isEmail) {
            if (!$form.hasClass('login_by_email')) {
                $form.addClass('login_by_email');
                $form.find('.input_password').closest('.input').removeClass('hidden');
            }
        } else if ($form.hasClass('login_by_email')) {
            $form.removeClass('login_by_email');
            $form.find('.input_password').closest('.input').addClass('hidden');
        }
        if (e.type === 'blur') {
            if (!isEmail && isPhone) {
                $form.removeClass('login_by_email');
                $form.find('.input_password').closest('.input').addClass('hidden');
                $form.addClass('login_by_phone');
                $form.find('#button_login').text(CONFIG.LANG.loginBySms);
                $form.attr('action', CONFIG.URL.loginPhone);
                $(this).attr('name', 'full_phone');
                $form.find('.input_password').attr('type', 'hidden');
            }
        }
    });
    // endregion

    // region Валидация и отправка формы
    $('.input .placeholder').on('click', function () {
        $(this).closest('.input').addClass('focus');
        $(this).closest('.input').find('input').focus();
        $(this).closest('.input').removeClass('success').removeClass('error');
    });

    const $form = $('#form_login_submit');
    $form.on('focus', '.input .input_login, .input .input_password', function () {
        $(this).closest('.input').addClass('focus');
        $(this).closest('.input').removeClass('success').removeClass('error');
    });
    $form.on('blur', '.input .input_login, .input .input_password', function () {
        let val = $(this).val();
        $(this).closest('.input').removeClass('focus');
        if (val === '') {
            $(this).closest('.input').removeClass('full');
        } else {
            $(this).closest('.input').addClass('full');
            let isNotValid;
            if ($(this).hasClass('input_password')) {
                const MIN_LENGTH_PASSWORD = 6;
                const MAX_LENGTH_PASSWORD = 20;
                isNotValid = val.length < MIN_LENGTH_PASSWORD || val.length > MAX_LENGTH_PASSWORD;
            } else {
                const MIN_LENGTH_EMAIL = 2;
                isNotValid = val.length < MIN_LENGTH_EMAIL || (!validatePhone(val) && !validateEmail(val));
            }
            if (isNotValid) {
                $(this).closest('.input').removeClass('success').addClass('error');
            } else {
                $(this).closest('.input').removeClass('error').addClass('success');
            }
        }
    });
    $('.input .input_password, .login_by_login_or_phone .input .input_login').on('keydown', function(e){
        const keyEnter = 13;
        if(e.keyCode === keyEnter){
            $(this).trigger('blur');
            $('#form_login_submit').submit();
        }
    });
    $('.input .input_login, .input .input_password').each(function () {
        if ($(this).val() !== '') {
            $(this).closest('.input').addClass('full');
        }
    });

    $('#form_login_submit.form_login #button_login').on('click', function (e) {
        e.preventDefault;
        const small_window = 880;
        if ($(window).width() <= small_window) {
            window.location = CONFIG.URL.login;
        }
    });

    $form.on('submit', function () {
        let isSubmit = true;
        if ($(this).hasClass('login_by_email')) {
            $(this).find('.input').each(function () {
                if ($(this).find('input').val() === '') {
                    isSubmit = false;
                    $(this).removeClass('success').addClass('error');
                } else if ($(this).hasClass('error') || !$(this).hasClass('full')) {
                    isSubmit = false;
                }
            });
        } else if ($(this).hasClass('login_by_phone')) {
            if (!$(this).hasClass('login_by_login_or_phone') && !iti.isValidNumberPrecise()) {
                isSubmit = false;
                validateNumber();
            }
        } else {
            isSubmit = false;
        }

        return isSubmit;
    });
    // endregion

    // region Авторизация по телефону
    const input = document.querySelector("#phone");
    const reset = () => {
        $('.error-text').empty();
        input.closest('.input').classList.remove("error");
        input.closest('.input').classList.remove("success");
    };
    const validateNumber = () => {
        reset();
        if (input.value.trim()) {
            if (iti.isValidNumberPrecise()) {
                input.closest('.input').classList.add("success");
            } else {
                input.closest('.input').classList.add("error");
                const errorCode = iti.getValidationError();
                const msg = errorCode === 1 ? CONFIG.LANG.phoneNotValid : CONFIG.LANG.wrongPhone;
                $(input.closest('form')).find('.error-text').text(msg);
            }
        }
    };
    let iti;
    if (input) {
        // https://intl-tel-input.com/
        iti = intlTelInput(input, {
            initialCountry: CONFIG.DATA.currentCountry,
            i18n: {
                // Пробел в начале специально, что бы они выводились первыми в списке
                by: ' Беларусь',
                ru: ' Россия',
                selectedCountryAriaLabel: CONFIG.LANG.selectedCountryAriaLabel,
                noCountrySelected: CONFIG.LANG.noCountrySelected,
                countryListAriaLabel: CONFIG.LANG.countryListAriaLabel,
                searchPlaceholder: CONFIG.LANG.searchPlaceholder,
                zeroSearchResults: CONFIG.LANG.zeroSearchResults,
                oneSearchResult: CONFIG.LANG.oneSearchResult,
                multipleSearchResults: CONFIG.LANG.multipleSearchResults,
            },
            countrySearch: true,
            nationalMode: false,
            autoInsertDialCode: false,
            showSelectedDialCode: true,
            hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),
            utilsScript: 'build/js/intl-tel-input/utils.js',
        });
        $(input).on('countrychange', function () {
            const selectCountry = iti.getSelectedCountryData().iso2;
            if (typeof selectCountry !== 'undefined' && CONFIG.DATA.availableLoginPhoneCountry.join(',').indexOf(selectCountry.toUpperCase()) < 0) {
                window.location = CONFIG.URL.homeCountry + '/' + selectCountry;
            }
        });

        input.addEventListener('change keyup focus', reset);
        input.addEventListener('blur', validateNumber);
        iti.promise.then(()=>{
            if (input.value.trim()) {
                validateNumber();
            }
        });
    }
    // endregion

    // region Ввод кода
    const inputCode = $(".wrap_input_code");
    if (inputCode.length > 0) {
        const inputs = inputCode.find('input');
        inputs.first().focus();
        inputs.each(function (index, input) {
            let isCtrlPressed = false;

            $(input).on('input', function (e) {
                const text = e.target.value;
                if (/^\d+$/.test(text)) {
                    const numbersArray = text.split('');
                    numbersArray.forEach((number, i) => {
                        if (index + i < inputs.length) {
                            inputs[index + i].value = number;
                            if (index + i < inputs.length - 1) {
                                inputs[index + i + 1].focus();
                            } else {
                                $(input).closest('form').find('#button_login').focus();
                            }
                        }
                    });
                } else {
                    e.target.value = '';
                }
            });
            $(this).on('keydown', function (e) {
                const key = e.key;
                if (key.length === 1 && !(isCtrlPressed && key === 'v') && !/\d/.test(key)) {
                    e.preventDefault();
                }
                const keyControl = 17;
                if (e.keyCode === keyControl) {
                    isCtrlPressed = true;
                }
            });
            $(this).on('keyup', function (e) {
                const keyEnter = 13;
                const keyControl = 17;
                const keyBackspace = 8;
                const keyArrowLeft = 37;
                const keyArrowRight = 39;
                if (e.keyCode === keyControl) {
                    isCtrlPressed = false;
                }
                if (e.keyCode === keyEnter) {
                    e.preventDefault();
                }
                if (e.keyCode === keyArrowRight) {
                    if ($(this).next().length > 0) {
                        $(this).next().focus().select();
                    }
                }
                if (e.keyCode === keyBackspace || e.keyCode === keyArrowLeft) {
                    if ($(this).prev().length > 0) {
                        $(this).prev().focus().select();
                    }
                }
            })
        });
    }
    // endregion

    // region Отправка формы с кодом
    $('#form_code_submit').on('submit', function (e) {
        const inputCode = $(".wrap_input_code");
        const inputs = inputCode.find('input');
        let code = '';
        inputs.each(function (index, input) {
            code += input.value;
        });
        if (code.length === 4) {
            $(this).find('[name=code]').val(code);
        } else {
            e.preventDefault();
        }
    });
    // endregion

    // region Форма выбора пользователя
    if ($('#form_select_user').length > 0) {
        tippyJs('.select_user_info', {
            content: CONFIG.LANG.waitActivate,
            arrow: true,
            theme: 'light select_user_not_active_text',
            placement: 'top-end',
            offset: "30, 2",
        });

        $('.wrap_select_user').on('click', function () {
            $(this).find('input').prop('checked', true);
            $(this).closest('form').submit();
        });
    }
    // endregion

    //region Форма регистрации
    $('#registerCity, #registerCompany, #registerAccount').on('submit', function (e) {
        e.preventDefault();
        const $form = $(this);
        if ($form.hasClass('disabled')) {
            return;
        }
        $form.addClass('disabled');
        clearErrors($form);
        $form.ajaxSubmit({
            success: function (data) {
                registerFormSuccess($form, data);
            },
            error: function (data) {
                registerFormError($form, data);
            }
        });
    });

    const registerFormSuccess = function ($form, data) {
        if (data.redirect) {
            $('.register').find('form:visible').removeClass('disabled');
            window.location = data.redirect;
            return;
        }
        changeRegisterStep(data.step);
        if (typeof data.isManager !== 'undefined') {
            const $address = $('#companyAddress').closest('.form-group');
            if (data.isManager) {
                $address.find('.not_required').hide();
                $address.find('input').prop('required', true);
            } else {
                $address.find('.not_required').show();
                $address.find('input').prop('required', false);
            }
        }
    };

    const registerFormError = function ($form, data) {
        $form.removeClass('disabled');
        if (data.responseJSON && data.responseJSON.result && data.responseJSON.result.errors) {
            const errors = data.responseJSON.result.errors;
            Object.keys(errors).map((field) => {
                const error = errors[field];
                const $field = $form.find('[name='+field+']').closest('.form-group');
                $field.addClass('error');
                $field.find('.error-text').html(error.join('<br>'));
            });
        }
        if (data.responseJSON && data.responseJSON.step) {
            changeRegisterStep(data.responseJSON.step);
        }
    };

    const clientTypeChosen = function() {
        const browser_is_supported = function() {
            if (window.navigator.appName === "Microsoft Internet Explorer") {
                return document.documentMode >= 8;
            }
            if (
                /iP(od|hone)/i.test(window.navigator.userAgent)
                || /IEMobile/i.test(window.navigator.userAgent)
                || /Windows Phone/i.test(window.navigator.userAgent)
                || /BlackBerry/i.test(window.navigator.userAgent)
                || /BB10/i.test(window.navigator.userAgent)
                || /Android.*Mobile/i.test(window.navigator.userAgent)
            ) {
                return false;
            }
            return true;
        };
        const $clientType = $('#clientType');
        if ($clientType.length > 0) {
            if (browser_is_supported()) {
                $clientType.chosen("destroy");
                $clientType.chosen($.extend({}, CONFIG.CHOSEN));
            } else {
                $clientType.addClass('not_supported');
            }
        }
    };
    clientTypeChosen();

    $('.link-back').on('click', function(e) {
        const $this = $(this);
        const step = $this.data('step');
        if (step > 1) {
            e.preventDefault();
            const prevStep = step - 1;
            changeRegisterStep(prevStep);
            $.post(CONFIG.URL.registerSetStep, {step: prevStep});
        }
    });

    const clearErrors = function($form) {
        $form.find('.form-group').each((i, el) => {
            $(el).removeClass('error');
            $(el).find('.error-text').empty();
        });
    };

    const changeRegisterStep = function(step) {
        const $currentForm = $('.register').find('form:visible');
        const $nextForm = $('form[data-step=' + step + ']');
        if (step === lastStepRegister) {
            $nextForm.submit();
            return;
        }
        $currentForm.removeClass('disabled');
        const $back = $('.link-back');
        const $titleStep = $('.title_description .title_step');

        if ($nextForm.length > 0) {
            $currentForm.hide();
            $nextForm.show();
            $back.data('step', step);
            $titleStep.text(step);

            clientTypeChosen();
        }
    };
    //endregion
});
